import { Autocomplete, AutocompleteRenderInputParams, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import PrimaryButton from '../button/PrimaryButton';
import { use } from 'i18next';
import axios from 'axios';
import { API_ENDPOINTS } from '../../utils/constants';
import { useAppDispatch } from '../../hooks/redux';

interface props {
  providers: string[];
  selectedProvider?: string;
  selectedModel?: string;
  goBack: () => void;
}

function NewPromptOptions({ providers, selectedModel, selectedProvider, goBack }: props) {
  const dispatch = useAppDispatch();
  const [language, setLanguage] = React.useState('');
  const [provider, setProvider] = React.useState(selectedProvider ?? '');
  const [userPrompt, setUserPrompt] = React.useState('');
  const [systemPrompt, setSystemPrompt] = React.useState('');
  const [providerOptions, setProviderOptions] = React.useState<string[]>([]);
  const [model, setModel] = React.useState(selectedModel ?? '');

  const handleBack = () => {
    goBack();
  };

  const handleSave = async () => {
    const params = {
      provider: provider,
      language: language,
      model: model,
      system_prompt: systemPrompt,
      user_prompt: userPrompt,
    };
    const response = await axios.post(`${API_ENDPOINTS.KARLA_FLASK_API}/operations/prompt/set`, params, {
      // headers: {
      // 	"Authorization" : `Bearer ${ACCESS_TOKEN}`
      // }
    });
    if (response.data.type === 'success') {
      dispatch({ type: 'DISPLAY_SUCCESS', payload: { reason: 'New prompt for has been saved' } });
    } else {
      dispatch({ type: 'DISPLAY_ERROR', payload: { reason: response.data.reason } });
    }
    goBack();
  };

  useEffect(() => {
    setProviderOptions(providers);
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div onClick={handleBack}>
          <PrimaryButton text={'Back'} />
        </div>
        <div onClick={handleSave}>
          <PrimaryButton text={'Save prompt'} />
        </div>
      </div>
      <br />

      <FormControl fullWidth>
        <InputLabel id="provider-label">Providers</InputLabel>
        <Select
          id="provider-select"
          value={provider}
          label="Age"
          onChange={(e) => {
            setProvider(e.target.value);
          }}
        >
          {providerOptions.map((option) => {
            return <MenuItem value={option}>{option}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <div style={{ display: 'flex' }}>
        <TextField
          id={'Model'}
          helperText={'Select model of new prompt here, should be all lowercase with underscore (_) instead of spaces.'}
          label="model"
          variant="outlined"
          value={model}
          style={{ marginTop: '1rem', marginRight: 5 }}
          fullWidth={true}
          onChange={(e) => {
            setModel(e.target.value);
          }}
        />
        <TextField
          id={'language'}
          helperText={'Select language of new prompt here, should be in 2 letter format, all lowercase. (ISO 639 language codes)'}
          label="Language"
          variant="outlined"
          value={language}
          style={{ marginTop: '1rem', marginLeft: 5 }}
          fullWidth={true}
          onChange={(e) => {
            setLanguage(e.target.value);
          }}
        />
      </div>
      <TextField
        id="outlined-multiline-static"
        label="System prompt"
        multiline
        minRows={4}
        fullWidth={true}
        style={{
          // width: '40%',
          marginTop: '1rem',
        }}
        helperText="This is the structure in which the model is given the system prompt. This where the rules for the model are set"
        variant="outlined"
        value={systemPrompt}
        onChange={(e) => {
          setSystemPrompt(e.target.value);
        }}
      />

      <TextField
        id="outlined-multiline-static"
        label="User prompt"
        multiline
        minRows={4}
        fullWidth={true}
        style={{
          // width: '40%',
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
        helperText="This is the structure in which the model is given the system prompt. This where the rules for the model are set"
        variant="outlined"
        value={userPrompt}
        onChange={(e) => {
          setUserPrompt(e.target.value);
        }}
      />
    </div>
  );
}

export default NewPromptOptions;
