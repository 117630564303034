interface DatastreamsReducerProps {
  type: 'SET_DATASTREAMS' | 'GET_DATASTREAMS';
  payload: any;
}

const datastreamsReducer = (state = false, action: DatastreamsReducerProps) => {
  switch (action.type) {
    case 'SET_DATASTREAMS':
      return action.payload;
    case 'GET_DATASTREAMS':
      return state;
    default:
      return state;
  }
};

export default datastreamsReducer;
