import Cookies from 'js-cookie';
import axios from 'axios';
import { APP_ROOT, API_ENDPOINTS } from '../utils/constants';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

type Response = {
  type: string;
  response: {
    reason: string;
    access_token?: any;
  };
};

interface PromptQuestionAction extends AnyAction {
  payload: Promise<void>;
}

const login = (params: any, dispatch: any): ThunkAction<Promise<void>, any, any, PromptQuestionAction> => {
  return async function loginThunk() {
    dispatch({ type: 'SET_IS_LOADING', payload: true });

    try {
      const response = await axios.post<Response>(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/operations/login`, {
        params: params,
        // headers: {
        // 	"Authorization" : `Bearer ${ACCESS_TOKEN}`
        // }
      });

      if (response.data && response.data.type === 'success') {
        Cookies.set('karla_authorization', response?.data?.response?.access_token, { expires: 7 });

        window.location.href = `${APP_ROOT}/dashboard`;
      }
    } catch (error) {
      dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error } });
    }

    dispatch({ type: 'SET_IS_LOADING', payload: false });
  };
};

export default login;
