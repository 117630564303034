import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import { ACCESS_TOKEN, API_ENDPOINTS } from '../utils/constants';
import { AnyAction } from 'redux';

interface GetDatastreamAction extends AnyAction {
  payload: Promise<any>;
}

const getDatastream = (params: any, dispatch: any): ThunkAction<Promise<any>, any, any, GetDatastreamAction> => {
  return async function getDatastreamThunk() {
    dispatch({ type: 'SET_IS_LOADING', payload: true });

    try {
      const response = await axios.get(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/stream/get`, {
        params: params,
      });
      if (response.data && response.data.type === 'success') {
        dispatch({ type: 'SET_IS_LOADING', payload: false });
        dispatch({ type: 'SET_DATASTREAM', payload: response?.data?.datastream });
      }

      if (response.data && response.data.type === 'error') {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: response.data.reason } });
      }
    } catch (error) {
      dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error } });
    }

    dispatch({ type: 'SET_IS_LOADING', payload: false });
    return null;
  };
};

export default getDatastream;
