import { Typography, Box } from '@mui/material';

const Header = ({ title }: any) => {
  return (
    <div className="content-panel__top">
      <Box>
        <Typography variant="h1">{title}</Typography>
      </Box>
      <Box></Box>
    </div>
  );
};

export default Header;
