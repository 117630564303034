import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { getDocumentList } from '../../hooks/firestore';
import getModels from '../../api/get-models';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ModelListItem from './ModelListItem';
import { ListItemText, ListSubheader } from '@mui/material';

const ModelList = () => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector((state) => state.organization);
  const models = useAppSelector((state) => state.models);

  useEffect(() => {
    if (!models && organization) {
      // const fetch = async () => {
      //   const response = await getDocumentList('models', 'owner', organization?.id);

      //   if (response) {
      //     dispatch({ type: 'SET_MODELS', payload: response });
      //   }
      // };

      // fetch();
      const getModelsThunk = getModels(
        {
          uid: organization?.id,
        },
        dispatch,
      );

      dispatch(getModelsThunk);
    }
  }, [models, organization]);

  return (
    <Grid item xs={12} md={6} className="model-list" style={{ marginTop: 10 }}>
      Models:
      <List>
        <ListSubheader className={'operations-list-header'}>
          <ListItemText primary={'Model name'} className="list-header-elem" />
          <ListItemText primary={'ID'} className="list-header-elem" />
        </ListSubheader>
        {models &&
          models?.map((item: any) => {
            return <ModelListItem key={item.id} item={item} />;
          })}
      </List>
    </Grid>
  );
};

export default ModelList;
