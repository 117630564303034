import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import axios from 'axios';
import { API_ENDPOINTS } from '../../utils/constants';
import { dkkFormatter } from '../../utils/helpers';
import { Box } from '@mui/material';
import ButtontContentWrapper from '../button/ButtonContentWrapper';

interface Props {
  title: string;
  description: string;
  values: [Dayjs, number];
}

function NextBilling({ title, description, values }: Props) {
  const [cashflow, setCashflow] = useState({ 'month_-1': { value: 0, history: [] }, month_0: { value: 0, history: [] }, month_1: { value: 0, history: [] }, month_2: { value: 0, history: [] }, month_3: { value: 0, history: [] } });
  const [mmr, setMmr] = useState({ value: 0, history: [] });

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${API_ENDPOINTS.KARLA_FLASK_API}/operations/billing/get`, {
        params: {},
        // headers: {
        // 	"Authorization" : `Bearer ${ACCESS_TOKEN}`
        // }
      });
      if (response.data && response.data.type === 'success') {
        const data = response.data.response;
        setCashflow(data.cashflow);
        setMmr(data.mmr);
      }
    };
    fetchData();
  }, []);

  const mmrChild = mmr.history.map((elem: any) => (
    <div>
      <span>
        {elem['user name'].toString()} : {elem?.uid.toString()}
      </span>
      <span style={{ margin: 10, marginLeft: 50 }}>+{dkkFormatter.format(elem?.price.toString())}</span>
    </div>
  ));

  const monthChild = (month: any) => {
    return month.history.map((elem: any) => (
      <div>
        <span>
          {elem['user name'].toString()} : {elem?.uid.toString()}
        </span>
        <span style={{ margin: 10, marginLeft: 50 }}>+{dkkFormatter.format(elem?.price)}</span>
      </div>
    ));
  };

  return (
    <>
      <h1>{title}</h1>
      <p>{description}</p>
      <Box className="stats-panel">
        <ButtontContentWrapper contentTitle={dayjs().subtract(1, 'month').format('MMMM').toString()} contentValue={dkkFormatter.format(cashflow['month_-1'].value)} buttonText={'See history'} modalChildren={monthChild(cashflow['month_-1'])} />
        <ButtontContentWrapper contentTitle={dayjs().format('MMMM').toString()} contentValue={dkkFormatter.format(cashflow.month_0.value)} buttonText={'See history'} modalChildren={monthChild(cashflow['month_0'])} />
        <ButtontContentWrapper contentTitle={dayjs().add(1, 'month').format('MMMM').toString()} contentValue={dkkFormatter.format(cashflow.month_1.value)} buttonText={'See history'} modalChildren={monthChild(cashflow['month_1'])} />
        <ButtontContentWrapper contentTitle={dayjs().add(2, 'month').format('MMMM').toString()} contentValue={dkkFormatter.format(cashflow.month_2.value)} buttonText={'See history'} modalChildren={monthChild(cashflow['month_2'])} />
        <ButtontContentWrapper contentTitle={dayjs().add(3, 'month').format('MMMM').toString()} contentValue={dkkFormatter.format(cashflow.month_3.value)} buttonText={'See history'} modalChildren={monthChild(cashflow['month_3'])} />
        <ButtontContentWrapper contentTitle={'MRR'} contentValue={dkkFormatter.format(mmr.value)} buttonText={'See history'} modalChildren={mmrChild} />
      </Box>
    </>
  );
}

export default NextBilling;
