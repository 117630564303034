import { Box } from '@mui/material';
import React from 'react';
import ContentPanelField from './ContentPanelField';

interface Props {
  value: [string, string, string, string];
  title: string;
  subtitles?: [string, string, string, string];
}

function ContentPanelCollection({ value, title, subtitles }: Props) {
  return (
    <>
      <h1 style={{ margin: 0, marginTop: 50 }}>{title}</h1>
      <Box className="stats-panel">
        <ContentPanelField title={subtitles ? subtitles[0] : 'Today'} value={value[0] ?? 0} />
        <ContentPanelField title={subtitles ? subtitles[1] : 'This month'} value={value[1] ?? 0} />
        <ContentPanelField title={subtitles ? subtitles[2] : 'Last month'} value={value[2] ?? 0} />
        <ContentPanelField title={subtitles ? subtitles[3] : 'This year'} value={value[3] ?? 0} />
      </Box>
    </>
  );
}

export default ContentPanelCollection;
