import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../hooks/redux';
import { authUser } from '../utils/authentication';

interface Props {
  redirectPath?: string;
  children?: React.ReactNode | any;
}

const ProtectedRoute = ({ redirectPath = '/login', children }: Props) => {
  if (!authUser()) {
    return <Navigate to={redirectPath} />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
