import axios from 'axios';
import { updateDocument } from '../hooks/firestore';
import { API_ENDPOINTS } from '../utils/constants';

interface ModelReducerProps {
  type: 'SET_ORGANIZATION' | 'GET_ORGANIZATION' | 'UPDATE_ORGANIZATION';
  payload: any;
}

const modelReducer = (state = false, action: ModelReducerProps) => {
  switch (action.type) {
    case 'SET_ORGANIZATION':
      return action.payload;
    case 'GET_ORGANIZATION':
      return state;
    case 'UPDATE_ORGANIZATION':
      if (action.payload && action.payload.id) {
        updateOrganization(action.payload);
      }
      return state;
    default:
      return state;
  }
};

export default modelReducer;

const updateOrganization = (payload: any) => {
  const fetch = async () => {
    const params = {
      org_id: payload.id,
      organization: payload,
    };
    await axios.patch(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/operations/organizations/set`, params, {
      // headers: {
      // 	"Authorization" : `Bearer ${ACCESS_TOKEN}`
      // }
    });
  };
  fetch();
};
