import { updateDocument } from '../hooks/firestore';

interface ModelReducerProps {
  type: 'SET_MODEL' | 'GET_MODEL' | 'UPDATE_MODEL';
  payload: any;
}

const modelReducer = (state = false, action: ModelReducerProps) => {
  switch (action.type) {
    case 'SET_MODEL':
      return action.payload;
    case 'GET_MODEL':
      return state;
    case 'UPDATE_MODEL':
      if (action.payload?.id) {
        console.log('action?.payload?.id', action?.payload?.id, 'action.payload', action.payload, 'displaynmae', action.payload?.displayName);
        updateDocument('models', action?.payload?.id, action.payload);
      }
      return state;
    default:
      return state;
  }
};

export default modelReducer;
