interface ConversationsReducerProps {
  type: 'SET_CONVERSATIONS' | 'GET_CONVERSATIONS';
  payload: any;
}

const conversationsReducer = (state = false, action: ConversationsReducerProps) => {
  switch (action.type) {
    case 'SET_CONVERSATIONS':
      return action.payload;
    case 'GET_CONVERSATIONS':
      return state;
    default:
      return state;
  }
};

export default conversationsReducer;
