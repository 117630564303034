import Cookies from 'js-cookie';
import { ACCESS_TOKEN } from './constants';

const authUser = () => {
  if (ACCESS_TOKEN === undefined) {
    return false;
  }

  return true;
};

const authDestroy = () => {
  Cookies.remove('karla_authorization');
  window.location.href = '/login';
};

export { authUser, authDestroy };
