interface SetIsLoadingAction {
  type: 'SET_IS_LOADING';
  payload: boolean;
}

const loadingReducer = (state = false, action: SetIsLoadingAction) => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return action.payload;
    default:
      return state;
  }
};

export default loadingReducer;
