interface AuthenticatedProps {
  type: 'SET_IS_AUTHENTICATED';
  payload: boolean;
}

const authenticatedReducer = (state = false, action: AuthenticatedProps) => {
  switch (action.type) {
    case 'SET_IS_AUTHENTICATED':
      return action.payload;
    default:
      return state;
  }
};

export default authenticatedReducer;
