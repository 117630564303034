interface ModelsReducerProps {
  type: 'SET_MODELS' | 'GET_MODELS';
  payload: any;
}

const modelsReducer = (state = false, action: ModelsReducerProps) => {
  switch (action.type) {
    case 'SET_MODELS':
      return action.payload;
    case 'GET_MODELS':
      return state;
    default:
      return state;
  }
};

export default modelsReducer;
