import Cookies from 'js-cookie';
const HOST = window.location.hostname;

type Environment = {
  ENV: string;
  SITE_HOST: string;
  APP_ROOT: string;
  API_ENDPOINTS: any;
  CHAT_ROOT: string;
  STATIC_ROOT: string;
  GCLOUD: string;
};

const production: Environment = {
  ENV: 'production',
  SITE_HOST: HOST,
  APP_ROOT: 'https://operations.karlachat.com',
  API_ENDPOINTS: {
    KARLA_FLASK_API: 'https://api.karlahub.com/v1',
  },
  CHAT_ROOT: 'https://chat.karlachat.com',
  STATIC_ROOT: 'https://static.karlachat.com',
  GCLOUD: 'https://console.cloud.google.com/storage/browser/karlachat-prod/',
};

const stage: Environment = {
  ENV: 'staging',
  SITE_HOST: HOST,
  APP_ROOT: 'https://karlachat-operations-staging.web.app',
  API_ENDPOINTS: {
    KARLA_FLASK_API: 'https://api.staging.karlahub.com/v1',
  },
  CHAT_ROOT: 'https://karlachat-chat-staging.web.app',
  STATIC_ROOT: 'https://karlachat-static-staging.web.app',
  GCLOUD: 'https://console.cloud.google.com/storage/browser/karlachat-staging/',
};

const local: Environment = {
  ENV: 'local',
  SITE_HOST: HOST,
  APP_ROOT: 'http://localhost:3003',
  API_ENDPOINTS: {
    KARLA_FLASK_API: 'http://127.0.0.1:5050/v1',
  },
  CHAT_ROOT: 'http://localhost:3001',
  STATIC_ROOT: 'http://localhost:3002',
  GCLOUD: 'https://console.cloud.google.com/storage/browser/karlachat-staging/',
};

const getConstant = (name: keyof Environment) => {
  switch (HOST) {
    case 'localhost':
      return local[name];
    case 'karlachat-operations-staging.web.app':
      return stage[name];
    default:
      return production[name];
  }
};

export const ENV = getConstant('ENV');
export const SITE_HOST = getConstant('SITE_HOST');
export const APP_ROOT = getConstant('APP_ROOT');
export const API_ENDPOINTS = getConstant('API_ENDPOINTS');
export const CHAT_ROOT = getConstant('CHAT_ROOT');
export const STATIC_ROOT = getConstant('STATIC_ROOT');
export const ACCESS_TOKEN = Cookies.get('karla_authorization');
export const GCLOUD = getConstant('GCLOUD');
