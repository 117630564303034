import { io, Socket } from 'socket.io-client';

import { API_ENDPOINTS } from '../utils/constants';

interface AuthAction {
  type: 'CONNECT_SOCKET' | 'GET_SOCKET';
  which?: string;
  payload: any;
}

const connectSocket = (endpoint: string) => {
  const ss: Socket = io(endpoint, {
    transports: ['websocket'],
  });

  return ss;
};

const endpoint = API_ENDPOINTS.KARLA_FLASK_API;

const socketReducer = (state = { collect: false }, action: AuthAction) => {
  switch (action.type) {
    case 'CONNECT_SOCKET':
      switch (action?.which) {
        case 'CONNECT_COLLECT':
          return { ...state, collect: connectSocket(`${endpoint}/karla/streams/collect`) };

        default:
          return state;
      }

      return {};
    case 'GET_SOCKET':
      return action.payload;
    default:
      return state;
  }
};

export default socketReducer;
