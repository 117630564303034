import { useAppSelector, useAppDispatch } from '../../hooks/redux';

import DisplaySuccess from './DisplaySuccess';
import DisplayWarning from './DisplayWarning';
import DisplayError from './DisplayError';

const DisplayMessage = (props: any) => {
  const dispatch = useAppDispatch();
  const display_message = useAppSelector((state) => state.display_message);

  if (!display_message) {
    return <></>;
  }

  const snackbarHandleClose = (event: React.SyntheticEvent, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({ type: 'REMOVE_MESSAGES', payload: true });
  };

  if (display_message?.type === 'success') {
    return <DisplaySuccess snackbarHandleClose={snackbarHandleClose} display_message={display_message} {...props} />;
  }

  if (display_message?.type === 'error') {
    return <DisplayError snackbarHandleClose={snackbarHandleClose} display_message={display_message} {...props} />;
  }

  if (display_message?.type === 'warning') {
    return <DisplayWarning snackbarHandleClose={snackbarHandleClose} display_message={display_message} {...props} />;
  }

  return <></>;
};

export default DisplayMessage;
