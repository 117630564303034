import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux';
import PrimaryButton from '../button/PrimaryButton';
import { GCLOUD } from '../../utils/constants';

interface DataCollectedItem {
  value: {
    gcloud_data_file_path: string;
  };
}

const BucketLink = () => {
  const datastream = useAppSelector((state) => state.datastream);

  if (!datastream) {
    return (
      <div style={{ marginTop: 5, marginBottom: 5 }}>
        <h1> Cannot find datastream </h1>
      </div>
    );
  }

  const cleanUrl = (url: string) => {
    const parts = url.split('/');
    return parts.slice(0, -1).join('/');
  };

  const dataIndexedUrl = Array.isArray(datastream?.data_indexes) && datastream?.data_indexes.length > 0 ? datastream.data_indexes[0]?.gcloud_data_file_path : null;

  let urlToOpen: string | null = null;

  if (Array.isArray(datastream?.data_collected) && datastream.data_collected.length > 0) {
    const highestDataCollectedUrl = datastream.data_collected.reduce((prev: DataCollectedItem, current: DataCollectedItem) => (prev?.value?.gcloud_data_file_path > current?.value?.gcloud_data_file_path ? prev : current), datastream.data_collected[0])?.value?.gcloud_data_file_path;

    urlToOpen = dataIndexedUrl ? cleanUrl(dataIndexedUrl) : highestDataCollectedUrl ? cleanUrl(highestDataCollectedUrl) : null;
  } else {
    urlToOpen = dataIndexedUrl ? cleanUrl(dataIndexedUrl) : null;
  }

  if (!urlToOpen) {
    return (
      <div style={{ marginTop: 5, marginBottom: 5 }}>
        <h1> Cannot find bucket link :( </h1>
        <p>Please ensure the datastream has finished crawling</p>
      </div>
    );
  }

  const goToCollectedData = () => {
    if (urlToOpen) {
      window.open(`${GCLOUD}${urlToOpen}`, '_blank');
    }
  };

  return (
    <div style={{ marginTop: 5, marginBottom: 5 }}>
      <PrimaryButton text={`Bucket link for: ${datastream.name}`} handleClick={goToCollectedData} />
    </div>
  );
};

export default BucketLink;
