import { useState } from 'react';

import Box from '@mui/material/Box';
import MuiModal from '@mui/material/Modal';

import PrimaryButton from '../button/PrimaryButton';
import { Typography } from '@mui/material';
import IconButton from '../button/IconButton';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface ModalProps {
  buttonText: string;
  buttonClass?: string;
  title?: string;
  description?: string;
  size?: string;
  handleOpenChange?: any;
  handleCloseChange?: any;
  children: any;
}

const Modal = ({ buttonText, buttonClass = '', title = '', description = '', size = 'large', handleOpenChange = false, handleCloseChange = false, children }: ModalProps) => {
  const [open, setOpen] = useState(handleOpenChange);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    if (handleCloseChange) {
      handleCloseChange(false);
    }
  };

  let style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    maxWidth: 800,
    pt: 2,
    px: 4,
    pb: 3,
  };

  if (size === 'medium') {
    style = { ...style, maxWidth: 600 };
  }

  if (size === 'small') {
    style = { ...style, maxWidth: 400 };
  }

  return (
    <>
      {buttonText && <PrimaryButton text={buttonText} className={buttonClass} handleClick={handleOpen} />}

      <MuiModal open={open} onClose={handleClose}>
        <Box
          className="basic__modal-box"
          sx={style}
          // disabled={ loading }
        >
          <Box className="basic__modal-box-header">
            {title && (
              <Typography className="basic__modal-box-title" variant="h2">
                {title}
              </Typography>
            )}

            {description && (
              <Typography className="basic__modal-box-description" variant="body1">
                {description}
              </Typography>
            )}
          </Box>

          <IconButton className="basic__modal-box-close" icon={<CloseRoundedIcon />} handleClick={handleClose} />

          {children}
        </Box>
      </MuiModal>
    </>
  );
};

export default Modal;
