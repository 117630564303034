import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../hooks/redux';
import { getDocument } from '../hooks/firestore';
import { useTranslation } from 'react-i18next';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

import { FIREBASE_AUTH } from '../utils/firebase';

import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';

import PrimaryButton from '../components/button/PrimaryButton';
import StrengthIndicator from '../components/password/StrengthIndicator';

import countries from '../utils/country';
import { passwordController } from '../utils/password-controller';

import { handleUserLoginErrors } from '../utils/handle-errors';

import login from '../api/login';

// import logo from '../assets/img/logo.png';

const LogIn = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const loginThunk = login(userCredentials, dispatch);

    dispatch(loginThunk);
  };

  const [userCredentials, setUserCredentials] = useState({
    password: '',
  });

  return (
    <Box className="sign-up__container">
      <form className="sign-up__form form" onSubmit={handleSubmit}>
        <FormControl className="form__fields">
          <TextField
            label="Adgangskode"
            type="password"
            onChange={(event) => {
              const updatedCredentials = { ...userCredentials, password: event?.target?.value };
              setUserCredentials(updatedCredentials);
            }}
            variant="outlined"
            key={'password-input'}
            autoComplete="new-password"
          />

          <PrimaryButton text="Login" handleType="submit" />
        </FormControl>
      </form>
    </Box>
  );
};

export default LogIn;
