import { Link } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SnippetFolderRoundedIcon from '@mui/icons-material/SnippetFolderRounded';

const DatastreamListItem = ({ item }: any) => {
  const organization = useAppSelector((state) => state.organization);
  return (
    <Link className={'operations-list-item'} to={`/customers/${organization?.id}/datastream/${item?.id}`} state={{ datastream: item }}>
      <ListItem>
        <ListItemIcon>
          <SnippetFolderRoundedIcon />
        </ListItemIcon>
        {/* <ListItemText primary={item?.name} secondary={item.id} /> */}
        <ListItemText secondary={item?.name ?? '<No Name Found>'} sx={{ width: '50%' }} />
        <ListItemText secondary={item?.id ?? '<No ID Found>'} sx={{ width: '50%' }} />
      </ListItem>
    </Link>
  );
};

export default DatastreamListItem;
