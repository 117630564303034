import axios from 'axios';
import { API_ENDPOINTS } from '../utils/constants';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
interface TrainModelAction extends AnyAction {
  payload: Promise<void>;
}

const trainModel = (params: any, dispatch: any): ThunkAction<Promise<void>, any, any, TrainModelAction> => {
  return async function trainModelThunk() {
    dispatch({ type: 'SET_IS_LOADING', payload: true });

    dispatch({ type: 'DISPLAY_WARNING', payload: { reason: 'Hold on while the model is being trained.' } });

    try {
      const response = await axios.post(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/model/train`, {
        params: params,
        // headers: {
        // 	"Authorization" : `Bearer ${ACCESS_TOKEN}`
        // }
      });

      if (response.data && response.data.type === 'success') {
        console.log('response', response);

        // const newVersion = {
        //   setup: params?.model?.setup,
        //   metadata: params?.model?.metadata,
        //   styles: params?.model?.styles,
        //   files: response.data?.response,
        // };

        // const updatedVersions = Array.isArray(params?.model?.versions) ? params?.model?.versions.concat(newVersion) : [newVersion];
        // const updatedModel = { ...params?.model, versions: updatedVersions };

        // dispatch({ type: 'UPDATE_MODEL', payload: updatedModel });
        // dispatch({ type: 'SET_MODEL', payload: updatedModel });

        dispatch({ type: 'DISPLAY_SUCCESS', payload: { reason: 'The model is now ready to be published!' } });
      }

      if (response.data && response.data.type === 'error') {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: response.data.reason } });
      }
    } catch (error) {
      dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error } });
    }

    dispatch({ type: 'SET_IS_LOADING', payload: false });
  };
};

export default trainModel;
