import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import logo from '../../assets/img/logo.png';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { useTranslation } from 'react-i18next';

import SmartToyRoundedIcon from '@mui/icons-material/SmartToyRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Skeleton from '@mui/material/Skeleton';

import PrimaryButton from '../../button/PrimaryButton';

import Header from './Header';
import Navigation from './Navigation';
import ToggleSettings from './ToggleSettings';
import Avatar from './Avatar';

const LeftPanel = ({ children }: any) => {
  const user = useAppSelector((state) => state.user);
  const { t } = useTranslation();

  return (
    <section className="left-panel">
      <Header />

      <Navigation />

      <div className="left-panel__user">
        {/* <PrimaryButton className="left-panel__user-btn" icon={<AddRoundedIcon />} text={t('Add content')} /> */}

        <div className="left-panel__user-info">
          <div className="left-panel__user-avatar">
            <Avatar />
          </div>
          <div className="left-panel__user-names">{user ? 'test' : <Skeleton variant="rectangular" width={`100%`} height={30} />}</div>
          <div className="left-panel__user-settings">
            <ToggleSettings />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeftPanel;
