import { IconButton as MUIiconButton } from '@mui/material';

import { IconButtonInterface } from '../../types/ButtonInterface';

const IconButton = ({ className, icon, handleClick }: IconButtonInterface) => {
  return (
    <MUIiconButton className={className} onClick={handleClick}>
      {icon}
    </MUIiconButton>
  );
};

export default IconButton;
