import React from 'react';
import ContentPanelField from '../conversations/ContentPanelField';
import Modal from '../modals/Modal';
import dayjs from 'dayjs';

interface Props {
  contentTitle: string;
  contentValue: string;
  buttonText: string;
  modalChildren: any;
}

function ButtonContentWrapper({ contentTitle, contentValue, buttonText, modalChildren }: Props) {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flexDirection: 'column' }}>
        <ContentPanelField title={contentTitle} value={contentValue} />
        <Modal buttonText={buttonText} children={modalChildren} />
      </div>
    </div>
  );
}

export default ButtonContentWrapper;
