const getLocalLanguage = (): string => {
  let lang: string | null = localStorage.getItem('lang');

  if (lang == null) {
    lang = setLocalLanguage();
  }

  return lang;
};

const setLocalLanguage = (i18n: false | { changeLanguage(lang: string): void } = false, lang: string | false = false): string => {
  if (lang === false) {
    lang = detectLanguage();
  }

  if (i18n !== false) {
    i18n.changeLanguage(lang);
  }

  lang = lang.replace('-', '_');
  localStorage.setItem('lang', lang);

  return lang;
};

const detectLanguage = (): string => {
  return navigator.language || (navigator as any).userLanguage || 'da_DK';
};

export { setLocalLanguage, getLocalLanguage };
