import React, { useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import updateUser from '../../api/update-user';
import getUser from '../../api/get-user';
import { RootState } from '../..';

interface UserSettingProps {
  label: string;
  field: string;
  orgid: string;
}

function UserSetting({ label, field, orgid }: UserSettingProps) {
  const [subscriptions, setSubscriptions] = useState<{ [key: string]: boolean }>({
    karla_mail: false,
    karla_search: false,
    karla_insights: false,
    karla_support: false,
    karla_chat: false,
  });

  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const organization = useAppSelector((state: RootState) => state.organization);

  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        if (organization && organization) {
          const fetchedSubscriptions = organization.subscriptions || {};
          // Merge the default state with the new fetched subscriptions
          setSubscriptions({
            karla_mail: fetchedSubscriptions.karla_mail ?? false,
            karla_search: fetchedSubscriptions.karla_search ?? false,
            karla_insights: fetchedSubscriptions.karla_insights ?? false,
            karla_support: fetchedSubscriptions.karla_support ?? false,
            karla_chat: fetchedSubscriptions.karla_chat ?? false,
          });
        } else {
          console.error('No billing info found in user data.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (orgid) {
      fetchOrganizationData();
    }
  }, [orgid, dispatch, organization]);

  const handleChange = (key: string) => async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });

    const newOrganization = {
      ...organization,
      subscriptions: {
        ...organization.subscriptions,
        [key]: checked,
      },
    };
    dispatch({ type: 'SET_ORGANIZATION', payload: newOrganization });
    dispatch({ type: 'UPDATE_ORGANIZATION', payload: newOrganization });
    dispatch({ type: 'SET_IS_LOADING', payload: false });
  };

  return (
    <div>
      {loading && <p>Loading user settings...</p>}
      {!loading && (
        <FormGroup>
          <FormControlLabel control={<Switch checked={subscriptions.karla_mail} onChange={handleChange('karla_mail')} />} label="Karla Mail" />
          <FormControlLabel control={<Switch checked={subscriptions.karla_search} onChange={handleChange('karla_search')} />} label="Karla Search" />
          <FormControlLabel control={<Switch checked={subscriptions.karla_insights} onChange={handleChange('karla_insights')} />} label="Karla Insights" />
          <FormControlLabel control={<Switch checked={subscriptions.karla_support} onChange={handleChange('karla_support')} />} label="Karla Support" />
          <FormControlLabel control={<Switch checked={subscriptions.karla_chat} onChange={handleChange('karla_chat')} />} label="Karla Chat" />
        </FormGroup>
      )}
    </div>
  );
}

export default UserSetting;
