import axios from 'axios';
import { API_ENDPOINTS } from '../utils/constants';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
interface GetUserAction extends AnyAction {
  payload: Promise<void>;
}

const getUser = (params: any, dispatch: any): ThunkAction<Promise<void>, any, any, GetUserAction> => {
  return async function getUserThunk() {
    dispatch({ type: 'SET_IS_LOADING', payload: true });

    try {
      const response = await axios.get(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/operations/users/get`, {
        params: params,
        // headers: {
        // 	"Authorization" : `Bearer ${ACCESS_TOKEN}`
        // }
      });

      console.log('response', response);
      if (response.data && response.data.type === 'success') {
        // console.log('response', response);
        dispatch({ type: 'SET_ORGANIZATION', payload: response?.data?.response });
        dispatch({ type: 'SET_USER', payload: response?.data?.response });
      }

      if (response.data && response.data.type === 'error') {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: response.data.reason } });
      }
    } catch (error) {
      dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error } });
    }

    dispatch({ type: 'SET_IS_LOADING', payload: false });
  };
};

export default getUser;
