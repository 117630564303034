import axios from 'axios';
import { API_ENDPOINTS } from '../utils/constants';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

interface CrawlAction extends AnyAction {
  payload: Promise<void>;
}

const collect = (params: any, datastreams: any, dispatch: any): ThunkAction<Promise<void>, any, any, CrawlAction> => {
  return async function collectThunk() {
    dispatch({ type: 'SET_IS_LOADING', payload: true });

    datastreams.map((stream: any) => {
      try {
        const runCrawl = async () => {
          params['stream'] = stream;
          const response = await axios.post(`${API_ENDPOINTS.KARLA_FLASK_API}/streams/collect`, {
            params: params,
            // headers: {
            // 	"Authorization" : `Bearer ${ACCESS_TOKEN}`
            // }
          });

          if (response.data && response.data.type === 'success') {
            // console.log('response', response);
            dispatch({ type: 'DISPLAY_SUCCESS', payload: { reason: 'Successfully collected data the streams' } });
          }
        };

        runCrawl();
      } catch (error) {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error } });
      }
    });

    dispatch({ type: 'SET_IS_LOADING', payload: false });
  };
};

export default collect;
