import { useNavigate, useParams, NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';

import logo from '../../../assets/img/birdie.png';

const Header = () => {
  const user = useAppSelector((state) => state.user);

  return (
    <div className="left-panel__top">
      <NavLink to="/">
        <img src={logo} alt="logo" />
      </NavLink>

      {user && (
        <>
          <div className="left-panel__top-plans">
            <div className="left-panel__top-plans-name">KarlaChat Organization</div>
            <div className="left-panel__top-plans-type">Enterprise Plan</div>
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
