import axios from 'axios';
import { API_ENDPOINTS } from '../utils/constants';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
interface GetModelAction extends AnyAction {
  payload: Promise<void>;
}

const getModel = (params: any, dispatch: any): ThunkAction<Promise<void>, any, any, GetModelAction> => {
  return async function getModelThunk() {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    try {
      const response = await axios.get(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/model/get`, {
        params: params,
        // headers: {
        // 	"Authorization" : `Bearer ${ACCESS_TOKEN}`
        // }
      });

      if (response.data && response.data.type === 'success') {
        dispatch({ type: 'SET_MODEL', payload: response?.data?.model });
      }

      if (response.data && response.data.type === 'error') {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: response.data.reason } });
      }
    } catch (error) {
      dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error } });
    }

    dispatch({ type: 'SET_IS_LOADING', payload: false });
  };
};

export default getModel;
