import React, { useEffect } from 'react';
import ContentPanel from '../components/sections/content-panel';
import LeftPanel from '../components/sections/left-panel';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PromptSettings from '../components/prompts/PromptSettings';
import { API_ENDPOINTS } from '../utils/constants';
import axios from 'axios';
import NewPromptOptions from '../components/prompts/NewPromptOptions';
import DisplayMessage from '../components/messages/DisplayMessage';
import { lang } from 'moment';

function Prompts() {
  // const providerOptions = ["openAI", "azure_oai", "google_gemini"]
  const [providerOptions, setProviderOptions] = React.useState<string[]>([]);
  const [modelOptions, setModelOptions] = React.useState<string[]>([]);
  const [languageOptions, setLanguageOptions] = React.useState([]);
  const [language, setLanguage] = React.useState('');
  const [provider, setProvider] = React.useState('');
  const [userPrompt, setUserPrompt] = React.useState('');
  const [systemPrompt, setSystemPrompt] = React.useState('');
  const [allLanguages, setAllLanguages] = React.useState<any>();
  const [newPrompt, setNewPrompt] = React.useState(false);
  const [model, setModel] = React.useState('');

  const fetchProviders = async () => {
    const response = await axios.get(`${API_ENDPOINTS.KARLA_FLASK_API}/operations/providers/get`);
    if (response.data && response.data.type === 'success') {
      setProviderOptions(response.data.providers);
    }
  };
  useEffect(() => {
    fetchProviders();
  }, []);

  const handleProviderChange = (event: any) => {
    const fetchLanugages = async () => {
      const response = await axios.get(`${API_ENDPOINTS.KARLA_FLASK_API}/operations/prompt/model/get`, {
        params: {
          provider: event.target.value,
        },
      });

      if (response.data && response.data.type === 'success') {
        setModel('');
        setLanguage('');
        setUserPrompt('');
        setSystemPrompt('');
        setModelOptions(response.data.models);
      }
    };

    fetchLanugages();
    setProvider(event.target.value);
  };

  const handleModelChange = (event: any) => {
    const fetchLanugages = async () => {
      const response = await axios.get(`${API_ENDPOINTS.KARLA_FLASK_API}/operations/prompt/lang/get`, {
        params: {
          provider: provider,
          model: event.target.value,
        },
      });
      if (response.data && response.data.type === 'success') {
        const val = response.data.langs.map((lang: any) => lang.language);
        setLanguageOptions(val);
        setAllLanguages(response.data.langs);
        setLanguage('');
        setUserPrompt('');
        setSystemPrompt('');
        DisplayMessage('success');
      }
    };
    fetchLanugages();
    setModel(event.target.value);
  };

  const handleLanguageChange = (event: any) => {
    setUserPrompt('');
    setSystemPrompt('');
    const lang = event.target.value;
    setLanguage(lang);
    const val = allLanguages.find((langOption: any) => langOption.language === lang);
    setSystemPrompt(val.systemPrompt);
    setUserPrompt(val.userPrompt);
  };

  const handleClickNew = () => {
    setNewPrompt(true);
  };

  const handleGoBack = () => {
    setNewPrompt(false);
    setLanguage('');
    setUserPrompt('');
    setSystemPrompt('');
    setModel('');
    setProvider('');
    fetchProviders();
  };

  if (newPrompt) {
    return (
      <div className="app dashboard">
        <LeftPanel />

        <ContentPanel title={'Prompt settings'}>
          <div>
            <NewPromptOptions providers={providerOptions} selectedProvider={provider} selectedModel={model} goBack={handleGoBack} />
          </div>
        </ContentPanel>
      </div>
    );
  }

  return (
    <div className="app dashboard">
      <LeftPanel />
      <ContentPanel title={'Prompt settings'}>
        <div>
          <div style={{ display: 'flex', marginTop: 25 }}>
            <FormControl fullWidth>
              <InputLabel id="providers-label">Providers</InputLabel>
              <Select id="providers-selector" value={provider} label="Providers" onChange={handleProviderChange}>
                {providerOptions.map((option) => {
                  return <MenuItem value={option}>{option}</MenuItem>;
                })}

                <MenuItem style={{ width: '100%', justifyContent: 'flex-start', color: 'black' }} onClick={handleClickNew}>
                  Add new
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="model-label">Model</InputLabel>
              <Select id="model-selector" value={model} disabled={provider === ''} label="Model" onChange={handleModelChange}>
                {modelOptions.map((option) => {
                  return <MenuItem value={option}>{option}</MenuItem>;
                })}

                <MenuItem style={{ width: '100%', justifyContent: 'flex-start', color: 'black' }} onClick={handleClickNew}>
                  Add new
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="language-label">Languages</InputLabel>
              <Select id="language-selector" value={language} label="Language" disabled={model === ''} onChange={handleLanguageChange}>
                {languageOptions.map((option) => {
                  return <MenuItem value={option}>{option}</MenuItem>;
                })}
                <Button style={{ width: '100%', justifyContent: 'flex-start', color: 'black' }} onClick={handleClickNew} variant="text">
                  Add new
                </Button>
              </Select>
            </FormControl>
          </div>

          {language && <PromptSettings language={language} model={model} provider={provider} userPrompt={userPrompt} systemPrompt={systemPrompt} setSystemPrompt={setSystemPrompt} setUserPrompt={setUserPrompt} />}
        </div>
      </ContentPanel>
    </div>
  );
}

export default Prompts;
