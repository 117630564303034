import Header from './Header';

const ContentPanel = ({ title, children }: any) => {
  return (
    <section className="content-panel">
      <Header title={title} />
      {children}
    </section>
  );
};

export default ContentPanel;
