import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import getOrganizations from '../../api/get-organizations';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import OrganizationListItem from './OrganizationListItem';
import { Divider, ListItemText, ListSubheader } from '@mui/material';

const OrganizationList = () => {
  const dispatch = useAppDispatch();
  const organizations = useAppSelector((state) => state.organizations);
  const datastreams = useAppSelector((state) => state.datastreams);
  const models = useAppSelector((state) => state.models);
  let sortedOrganizations: any;

  useEffect(() => {
    if (!organizations) {
      const getOrganizationsThunk = getOrganizations({}, dispatch);

      dispatch(getOrganizationsThunk);
    }
    if (organizations) {
      // sort the list of organizations alphabetically by company name
      sortedOrganizations = organizations.sort((a: any, b: any) => {
        if (!a.metadata?.company_name && !b.metadata?.company_name) {
          return 0;
        }
        if (!a.metadata?.company_name) {
          return 1;
        }
        if (!b.metadata?.company_name) {
          return -1;
        }

        if (a.metadata.company_name < b.metadata.company_name) {
          return -1;
        }
        if (a.metadata.company_name > b.metadata.company_name) {
          return 1;
        }
        return 0;
      });

      dispatch({ type: 'SET_ORGANIZATIONS', payload: sortedOrganizations });
    }
  }, [organizations]);

  useEffect(() => {
    // The models and datastreams states should not be sat here. so we set them to false if they are
    if (datastreams || models) {
      dispatch({ type: 'SET_MODELS', payload: false });
      dispatch({ type: 'SET_DATASTREAMS', payload: false });
    }
  }, []);

  return (
    <Grid item xs={12} md={6} className="model-list">
      <List>
        <ListSubheader className={'operations-list-header'}>
          <ListItemText primary={'Organization Name'} className="list-header-elem" />
          <ListItemText primary={'Country'} className="list-header-elem" />
          <ListItemText primary={'ID'} className="list-header-elem" />
        </ListSubheader>
        {organizations &&
          organizations.map((item: any) => {
            return (
              <>
                <OrganizationListItem organization={item} />
                <Divider variant="middle" component="li" />
              </>
            );
          })}
      </List>
    </Grid>
  );
};

export default OrganizationList;
