interface OrganizationsReducerProps {
  type: 'SET_ORGANIZATIONS' | 'GET_ORGANIZATIONS';
  payload: any;
}

const organizationsReducer = (state = false, action: OrganizationsReducerProps) => {
  switch (action.type) {
    case 'SET_ORGANIZATIONS':
      return action.payload;
    case 'GET_ORGANIZATIONS':
      return state;
    default:
      return state;
  }
};

export default organizationsReducer;
