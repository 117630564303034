import { updateDocument } from '../hooks/firestore';

interface UserReducerProps {
  type: 'SET_USER' | 'GET_USER' | 'UPDATE_USER' | 'UPDATE_USER_DOCUMENT_SETTINGS';
  payload: any;
}

const setUserPersists = (user: any) => {
  if (!localStorage) {
    return false;
  }

  localStorage.setItem('user', JSON.stringify(user));
};

const getInitialState = () => {
  if (!localStorage) {
    return false;
  }

  if (!localStorage.getItem('user')) {
    return false;
  }

  return JSON.parse(localStorage.getItem('user') ?? '');
};

const userReducer = (state = getInitialState(), action: UserReducerProps) => {
  switch (action.type) {
    case 'SET_USER':
      setUserPersists(action.payload);
      return action.payload;
    case 'UPDATE_USER':
      if (action.payload?.uid && action?.payload?.document) {
        updateDocument('users', action?.payload?.uid, action.payload?.document);
      }
      return state;
    case 'GET_USER':
      return state;
    case 'UPDATE_USER_DOCUMENT_SETTINGS':
      if (action.payload?.user && action?.payload?.key && action?.payload?.value) {
        const user_document = action?.payload?.user?.document;
        const newUserDocument = { ...user_document, settings: { ...user_document?.settings, [action?.payload?.key]: action?.payload?.value } };

        const newUser = { ...action?.payload?.user, document: newUserDocument };

        updateDocument(action.payload?.user?.uid, newUser, newUserDocument);

        return newUser;
      }
      return state;
    default:
      return state;
  }
};

export default userReducer;
