const conversationReducer = (state: object[] = [], action: any) => {
  switch (action.type) {
    case 'SET_CONVERSATION':
      return action.payload;

    case 'GET_CONVERSATION':
      return action.payload;

    default:
      return state;
  }
};

export default conversationReducer;
