type DisplayMessageType = {
  type: string;
  message: string;
};

type MessageAction = { type: 'DISPLAY_ERROR'; payload: DisplayMessageType } | { type: 'DISPLAY_WARNING'; payload: DisplayMessageType } | { type: 'DISPLAY_SUCCESS'; payload: DisplayMessageType } | { type: 'REMOVE_MESSAGES'; payload: boolean };

const messageReducer = (state: null | DisplayMessageType = null, action: MessageAction): null | DisplayMessageType => {
  if (!action.payload) {
    return state;
  }

  switch (action.type) {
    case 'DISPLAY_ERROR':
      return { ...action.payload, type: 'error' };
    case 'DISPLAY_WARNING':
      return { ...action.payload, type: 'warning' };
    case 'DISPLAY_SUCCESS':
      return { ...action.payload, type: 'success' };
    case 'REMOVE_MESSAGES':
      return null;
    default:
      return state;
  }
};

export default messageReducer;
