import { useEffect, useState } from 'react';

import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import getEngagement from '../../api/get-engagement';

import Box from '@mui/material/Box';

import 'moment-timezone';
import moment from 'moment';
import ContentPanelCollection from './ContentPanelCollection';
import BillingInfo from '../../pages/BillingInfo';
import { API_ENDPOINTS } from '../../utils/constants';
import { dkkFormatter, usdFormatter } from '../../utils/helpers';
import NextBilling from './NextBilling';
import dayjs from 'dayjs';
moment().tz('CEST');

const Info = ({ uid }: any) => {
  const dispatch = useAppDispatch();
  const engagement = useAppSelector((state) => state.engagement);
  const organization = useAppSelector((state) => state.organization);

  const date = new Date();
  const thisYear = String(date.getFullYear());
  const thisMonth = String(date.getMonth() + 1).padStart(2, '0');
  const thisDate = String(date.getDate()).padStart(2, '0');

  const lastMonth = String(date.getMonth()).padStart(2, '0');

  let thisYearPath = thisYear;
  let thisMonthPath = `${thisYear}/months/${thisMonth}`;
  let thisDatePath = `${thisYear}/months/${thisMonth}/dates/${thisDate}`;

  let lastMonthPath = `${thisYear}/months/${lastMonth}`;

  if (uid && uid !== '') {
    thisYearPath = `${thisYear}/users/${uid}`;
    thisMonthPath = `${thisYear}/months/${thisMonth}/users/${uid}`;
    thisDatePath = `${thisYear}/months/${thisMonth}/dates/${thisDate}/users/${uid}`;

    lastMonthPath = `${thisYear}/months/${lastMonth}/users/${uid}`;
  }

  useEffect(() => {
    const runEngagementCall = () => {
      const getEngagementThunk = getEngagement(
        {
          docs: JSON.stringify([thisYearPath, thisMonthPath, thisDatePath, lastMonthPath]),
        },
        dispatch,
      );
      dispatch(getEngagementThunk);
    };

    runEngagementCall();

    setInterval(() => {
      runEngagementCall();
    }, 30000);
  }, [thisYearPath, thisMonthPath, thisDatePath]);

  return (
    <>
      <ContentPanelCollection title={'Amount of conversations'} value={[engagement[thisDatePath]?.conversations, engagement[thisMonthPath]?.conversations, engagement[lastMonthPath]?.conversations, engagement[thisYearPath]?.conversations]} />
      <ContentPanelCollection title={'Amount of messages'} value={[engagement[thisDatePath]?.messages, engagement[thisMonthPath]?.messages, engagement[lastMonthPath]?.messages, engagement[thisYearPath]?.messages]} />
      <ContentPanelCollection
        title={'Conversation length'}
        value={[(engagement[thisDatePath]?.messages / engagement[thisDatePath]?.conversations)?.toFixed(2), (engagement[thisMonthPath]?.messages / engagement[thisMonthPath]?.conversations)?.toFixed(2), (engagement[lastMonthPath]?.messages / engagement[lastMonthPath]?.conversations)?.toFixed(2), (engagement[thisYearPath]?.messages / engagement[thisYearPath]?.conversations)?.toFixed(2)]}
      />
      <h1 style={{ margin: 0, marginTop: 50 }}>Conversation rating</h1> {/*I didnt dare try to compress ratings*/}
      <Box className="stats-panel">
        <Box className="stats-panel__item">
          <Box className="stats-panel__item-value">
            {(
              ((engagement[thisDatePath]?.rate_conversation_1 ? engagement[thisDatePath]?.rate_conversation_1 : 0) +
                (engagement[thisDatePath]?.rate_conversation_2 ? engagement[thisDatePath]?.rate_conversation_2 * 2 : 0) +
                (engagement[thisDatePath]?.rate_conversation_3 ? engagement[thisDatePath]?.rate_conversation_3 * 3 : 0) +
                (engagement[thisDatePath]?.rate_conversation_4 ? engagement[thisDatePath]?.rate_conversation_4 * 4 : 0) +
                (engagement[thisDatePath]?.rate_conversation_5 ? engagement[thisDatePath]?.rate_conversation_5 * 5 : 0)) /
              engagement[thisDatePath]?.rate_conversation
            ).toFixed(2)}
          </Box>
          <Box className="stats-panel__item-label">Today</Box>
        </Box>
        <Box className="stats-panel__item">
          <Box className="stats-panel__item-value">
            {(
              ((engagement[thisMonthPath]?.rate_conversation_1 ? engagement[thisMonthPath]?.rate_conversation_1 : 0) +
                (engagement[thisMonthPath]?.rate_conversation_2 ? engagement[thisMonthPath]?.rate_conversation_2 * 2 : 0) +
                (engagement[thisMonthPath]?.rate_conversation_3 ? engagement[thisMonthPath]?.rate_conversation_3 * 3 : 0) +
                (engagement[thisMonthPath]?.rate_conversation_4 ? engagement[thisMonthPath]?.rate_conversation_4 * 4 : 0) +
                (engagement[thisMonthPath]?.rate_conversation_5 ? engagement[thisMonthPath]?.rate_conversation_5 * 5 : 0)) /
              engagement[thisMonthPath]?.rate_conversation
            ).toFixed(2)}
          </Box>
          <Box className="stats-panel__item-label">This month</Box>
        </Box>
        <Box className="stats-panel__item">
          <Box className="stats-panel__item-value">
            {(
              ((engagement[lastMonthPath]?.rate_conversation_1 ? engagement[lastMonthPath]?.rate_conversation_1 : 0) +
                (engagement[lastMonthPath]?.rate_conversation_2 ? engagement[lastMonthPath]?.rate_conversation_2 * 2 : 0) +
                (engagement[lastMonthPath]?.rate_conversation_3 ? engagement[lastMonthPath]?.rate_conversation_3 * 3 : 0) +
                (engagement[lastMonthPath]?.rate_conversation_4 ? engagement[lastMonthPath]?.rate_conversation_4 * 4 : 0) +
                (engagement[lastMonthPath]?.rate_conversation_5 ? engagement[lastMonthPath]?.rate_conversation_5 * 5 : 0)) /
              engagement[lastMonthPath]?.rate_conversation
            ).toFixed(2)}
          </Box>
          <Box className="stats-panel__item-label">Last month</Box>
        </Box>
        <Box className="stats-panel__item">
          <Box className="stats-panel__item-value">
            {(
              ((engagement[thisYearPath]?.rate_conversation_1 ? engagement[thisYearPath]?.rate_conversation_1 * 1 : 0) +
                (engagement[thisYearPath]?.rate_conversation_2 ? engagement[thisYearPath]?.rate_conversation_2 * 2 : 0) +
                (engagement[thisYearPath]?.rate_conversation_3 ? engagement[thisYearPath]?.rate_conversation_3 * 3 : 0) +
                (engagement[thisYearPath]?.rate_conversation_4 ? engagement[thisYearPath]?.rate_conversation_4 * 4 : 0) +
                (engagement[thisYearPath]?.rate_conversation_5 ? engagement[thisYearPath]?.rate_conversation_5 * 5 : 0)) /
              engagement[thisYearPath]?.rate_conversation
            ).toFixed(2)}
          </Box>
          <Box className="stats-panel__item-label">This year</Box>
        </Box>
      </Box>
      <ContentPanelCollection
        title={'Clickrate'}
        value={[
          ((100 * engagement[thisDatePath]?.message_link_click) / engagement[thisDatePath]?.conversations).toFixed(2) + '%',
          ((100 * engagement[thisMonthPath]?.message_link_click) / engagement[thisMonthPath]?.conversations).toFixed(2) + '%',
          ((100 * engagement[lastMonthPath]?.message_link_click) / engagement[lastMonthPath]?.conversations).toFixed(2) + '%',
          ((100 * engagement[thisYearPath]?.message_link_click) / engagement[thisYearPath]?.conversations).toFixed(2) + '%',
        ]}
      />
      <ContentPanelCollection
        title="Token usage cost"
        value={[
          dkkFormatter.format(engagement[thisDatePath]?.total_token_cost_dkk) + ' / ' + usdFormatter.format(engagement[thisDatePath]?.total_token_cost_usd),
          dkkFormatter.format(engagement[thisMonthPath]?.total_token_cost_dkk) + ' / ' + usdFormatter.format(engagement[thisMonthPath]?.total_token_cost_usd),
          dkkFormatter.format(engagement[lastMonthPath]?.total_token_cost_dkk) + ' / ' + usdFormatter.format(engagement[lastMonthPath]?.total_token_cost_usd),
          dkkFormatter.format(engagement[thisYearPath]?.total_token_cost_dkk) + ' / ' + usdFormatter.format(engagement[thisYearPath]?.total_token_cost_usd),
        ]}
      />
      <ContentPanelCollection
        title="Token conversation avg."
        value={[
          (engagement[thisDatePath]?.total_tokens / engagement[thisDatePath]?.conversations)?.toFixed(0),
          (engagement[thisMonthPath]?.total_tokens / engagement[thisMonthPath]?.conversations)?.toFixed(0),
          (engagement[lastMonthPath]?.total_tokens / engagement[lastMonthPath]?.conversations)?.toFixed(0),
          (engagement[thisYearPath]?.total_tokens / engagement[thisYearPath]?.conversations)?.toFixed(0),
        ]}
      />
      <ContentPanelCollection title="Mails sent" value={[engagement[thisDatePath]?.send_email, engagement[thisMonthPath]?.send_email, engagement[lastMonthPath]?.send_email, engagement[thisYearPath]?.send_email]} />
      {uid ? <BillingInfo /> : <NextBilling title={'Billing information'} description={'Here, the surrounding months expected cashflow is shown, aswell as our general Monthly Recurring Revenue (MRR)'} values={[dayjs(), 2]} />}
    </>
  );
};

export default Info;
