interface DatastreamReducerProps {
  type: 'GET_DATASTREAM' | 'SET_DATASTREAM';
  payload: any;
}

const datastreamReducer = (state = false, action: DatastreamReducerProps) => {
  switch (action.type) {
    case 'SET_DATASTREAM':
      return action.payload;
    case 'GET_DATASTREAM':
      return state;
    default:
      return state;
  }
};

export default datastreamReducer;
