import axios from 'axios';
import { API_ENDPOINTS } from '../utils/constants';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
interface GetEngagementAction extends AnyAction {
  payload: Promise<void>;
}

const getEngagement = (params: any, dispatch: any): ThunkAction<Promise<void>, any, any, GetEngagementAction> => {
  return async function getEngagementThunk() {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    console.log('set is loading to true');

    try {
      const response = await axios.get(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/operations/engagement/get`, {
        params: params,
        // headers: {
        // 	"Authorization" : `Bearer ${ACCESS_TOKEN}`
        // }
      });

      if (response.data && response.data.type === 'success') {
        console.log('response for engagement', response.data.engagement);
        dispatch({ type: 'SET_ENGAGEMENT', payload: response?.data?.engagement });
      }

      if (response.data && response.data.type === 'error') {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: response.data.reason } });
      }
    } catch (error) {
      dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error } });
    }
    console.log('set is loading to false');
    dispatch({ type: 'SET_IS_LOADING', payload: false });
  };
};

export default getEngagement;
