import Button from '@mui/material/Button';

import { PrimaryButtonInterface } from '../../types/ButtonInterface';

const PrimaryButton = ({ text, icon, disabled, handleType, className, handleClick }: PrimaryButtonInterface) => {
  return (
    <Button className={`btn primary-btn ${className} ${disabled ? 'btn--disabled' : ''}`} variant="contained" onClick={handleClick} type={handleType}>
      {icon}
      {text}
    </Button>
  );
};

export default PrimaryButton;
