import { Box, CircularProgress } from '@mui/material';
import { use } from 'i18next';
import React from 'react';
import { useAppSelector } from '../../hooks/redux';

interface Props {
  title: string;
  value: string;
}

function ContentPanelField({ title, value }: Props) {
  const loading = useAppSelector((state) => state.loading);
  return (
    <>
      <Box className="stats-panel__item">
        <Box className="stats-panel__item-label">{title}</Box>
        {!loading ? (
          <>
            <Box className="stats-panel__item-value">{value}</Box>
          </>
        ) : (
          <CircularProgress color="inherit" />
        )}
      </Box>
    </>
  );
}

export default ContentPanelField;
