import axios from 'axios';
import { API_ENDPOINTS } from '../utils/constants';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
interface GetModelsAction extends AnyAction {
  payload: Promise<void>;
}

const getModels = (params: any, dispatch: any): ThunkAction<Promise<void>, any, any, GetModelsAction> => {
  return async function getModelsThunk() {
    dispatch({ type: 'SET_IS_LOADING', payload: true });

    try {
      // console.log('runs');
      const response = await axios.get(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/operations/models/get`, {
        params: params,
        // headers: {
        // 	"Authorization" : `Bearer ${ACCESS_TOKEN}`
        // }
      });

      if (response.data && response.data.type === 'success') {
        // console.log('response', response);
        dispatch({ type: 'SET_MODELS', payload: response?.data?.response });
      }

      if (response.data && response.data.type === 'error') {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: response.data.reason } });
      }
    } catch (error) {
      dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error } });
    }

    dispatch({ type: 'SET_IS_LOADING', payload: false });
  };
};

export default getModels;
