import { combineReducers } from 'redux';

import messageReducer from './message-reducer';
import loadingReducer from './loading-reducer';
import conversationReducer from './conversation-reducer';
import userReducer from './user-reducer';
import authenticatedReducer from './authenticated-reducer';
import modelReducer from './model-reducer';
import modelsReducer from './models-reducer';
import embeddingsReducer from './embeddings-reducer';
import conversationsReducer from './conversations-reducer';
import engagementReducer from './engagement-reducer';
import organizationsReducer from './organizations-reducer';
import organizationReducer from './organization-reducer';
import datastreamsReducer from './datastreams-reducer';
import datastreamReducer from './datastream-reducer';

import socketReducer from './socket-reducer';

const reducers = combineReducers({
  display_message: messageReducer,
  loading: loadingReducer,
  conversation: conversationReducer,
  user: userReducer,
  model: modelReducer,
  models: modelsReducer,
  authenticated: authenticatedReducer,
  embeddings: embeddingsReducer,
  conversations: conversationsReducer,
  engagement: engagementReducer,
  organizations: organizationsReducer,
  organization: organizationReducer,
  datastreams: datastreamsReducer,
  datastream: datastreamReducer,
  socket: socketReducer,
});

export default reducers;
