import { useEffect, useState } from 'react';
import { useAppDispatch } from '../hooks/redux';
import { useLocation } from 'react-router-dom';

const RouteChange = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [previousPath, setPreviousPath] = useState(location?.pathname ?? '');

  // Listen on location change
  useEffect(() => {
    // Clear organizations and model(s) states
    if (previousPath.includes('/customers/') && !location?.pathname.includes('/models/')) {
      dispatch({ type: 'SET_ORGANIZATION', payload: false });
      dispatch({ type: 'SET_MODELS', payload: false });
    }

    setPreviousPath(location?.pathname);
  }, [location]);

  return <></>;
};

export default RouteChange;
