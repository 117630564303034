import React, { useEffect } from 'react';
import ContentPanel from '../sections/content-panel';
import { TextField } from '@mui/material';
import PrimaryButton from '../button/PrimaryButton';
import axios from 'axios';
import { API_ENDPOINTS } from '../../utils/constants';
import { useAppDispatch } from '../../hooks/redux';

interface props {
  language: string;
  model: string;
  provider: string;
  userPrompt: string;
  systemPrompt: string;
  setUserPrompt: (value: string) => void;
  setSystemPrompt: (value: string) => void;
}

function PromptSettings({ language, provider, model, userPrompt, systemPrompt, setSystemPrompt, setUserPrompt }: props) {
  const dispatch = useAppDispatch();

  const handleSaveButton = async () => {
    const params = {
      provider: provider,
      model: model,
      language: language,
      system_prompt: systemPrompt,
      user_prompt: userPrompt,
    };
    const response = await axios.post(`${API_ENDPOINTS.KARLA_FLASK_API}/operations/prompt/set`, params, {
      // headers: {
      // 	"Authorization" : `Bearer ${ACCESS_TOKEN}`
      // }
    });

    if (response.data.type === 'success') {
      dispatch({ type: 'DISPLAY_SUCCESS', payload: { reason: 'Modification saved' } });
    } else {
      dispatch({ type: 'DISPLAY_ERROR', payload: { reason: response.data.reason } });
    }
  };
  return (
    <div>
      <form onSubmit={handleSaveButton}>
        <TextField
          id="outlined-multiline-static"
          label="System prompt"
          multiline
          minRows={8}
          fullWidth={true}
          style={{
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
          helperText="This is the structure in which the model is given the system prompt. This where the rules for the model are set"
          variant="outlined"
          value={systemPrompt}
          onChange={(e) => {
            setSystemPrompt(e.target.value);
          }}
        />
        <TextField
          id="outlined-multiline-static"
          label="User prompt"
          multiline
          minRows={8}
          fullWidth={true}
          style={{
            // width: '40%',
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
          helperText="This is the structure the user's question will be given in. The model will see the user question in this format."
          variant="outlined"
          value={userPrompt}
          onChange={(e) => {
            setUserPrompt(e.target.value);
          }}
        />
        {/* </div> */}
        <div onClick={handleSaveButton}>
          <PrimaryButton text={'Save prompt settings'} />
        </div>
      </form>
    </div>
  );
}

export default PromptSettings;
