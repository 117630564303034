import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const productionConfig = {
  apiKey: 'AIzaSyD8n492v4OIwoLa1lP-8hetMSVEqn4sW2Y',
  authDomain: 'karlachat-9a5f8.firebaseapp.com',
  projectId: 'karlachat-9a5f8',
  storageBucket: 'karlachat-9a5f8.appspot.com',
  messagingSenderId: '939334878114',
  appId: '1:939334878114:web:41042a28d4c0722576a8fb',
};

const stageConfig = {
  apiKey: 'AIzaSyCjfZm06enQPe5ebHHt9kIjZ4N4FtmRHes',
  authDomain: 'karlachat-stage.firebaseapp.com',
  projectId: 'karlachat-stage',
  storageBucket: 'karlachat-stage.appspot.com',
  messagingSenderId: '928904184003',
  appId: '1:928904184003:web:2b8a7499f6fb98fc51a276',
};

const localConfig = {
  apiKey: 'AIzaSyCjfZm06enQPe5ebHHt9kIjZ4N4FtmRHes',
  authDomain: 'karlachat-stage.firebaseapp.com',
  projectId: 'karlachat-stage',
  storageBucket: 'karlachat-stage.appspot.com',
  messagingSenderId: '928904184003',
  appId: '1:928904184003:web:2b8a7499f6fb98fc51a276',
};

const getConfig = () => {
  switch (window.location.hostname) {
    case 'localhost':
      return localConfig;
    case 'karlachat-app-staging.web.app':
      return stageConfig;

    default:
      return productionConfig;
  }
};

// console.log('getConfig() karla platform', getConfig());

// Initialize Firebase
export const FIREBASE_APP = initializeApp(getConfig());
export const FIREBASE_AUTH = getAuth(FIREBASE_APP);
export const FIRESTORE_DB = getFirestore(FIREBASE_APP);
