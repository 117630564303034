import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PrimaryButton from '../button/PrimaryButton';
import { API_ENDPOINTS } from '../../utils/constants';
import axios from 'axios';

type Langs = {
  [key: string]: {
    language: string;
    systemPrompt: string;
    userPrompt: string;
  };
};

type Model = {
  langs: Langs;
  model: string;
};

type Provider = {
  models: Model[];
  provider: string;
};

type Prompts = {
  all_prompts: Provider[];
  type: string;
};

function SelectModel() {
  const dispatch = useAppDispatch();
  const model = useAppSelector((state) => state.model);
  const [LLMprovider, setLLMProvider] = useState<string>('');
  const [LLMmodel, setLLMModel] = useState<string>('');
  const [LLMlanguage, setLLMLanguage] = useState<string>('');

  const [modelOptions, setModelOptions] = useState<string[]>([]);
  const [languageOptions, setLanguageOptions] = useState<string[]>([]);

  const [providerOptions, setProviderOptions] = useState<string[]>(['']);
  const [allPrompts, setAllPrompts] = useState([]);

  const [langDisable, setLangDisable] = useState<boolean>(true);
  const [modelDisable, setModelDisable] = useState<boolean>(true);

  useEffect(() => {
    const fetchProviders = async () => {
      const response = await axios.get(`${API_ENDPOINTS.KARLA_FLASK_API}/operations/prompt/get`);
      if (response.data && response.data.type === 'success') {
        const extractModelNames = (data: Prompts): string[] => {
          return data.all_prompts.flatMap((provider) => provider.models.map((model) => model.model));
        };

        const providerSet = new Set<string>();
        const langSet = new Set<string>();
        const temp_model = extractModelNames(response.data);

        response.data.all_prompts.forEach((provider: any) => {
          providerSet.add(provider.provider);
        });

        response.data.all_prompts.forEach((provider: any) => {
          provider.models.forEach((model: any) => {
            model.langs.forEach((lang: any) => {
              langSet.add(lang.language);
            });
          });
        });

        setAllPrompts(response.data.all_prompts);
        setProviderOptions(Array.from(providerSet));
        setModelOptions(temp_model);
        setLanguageOptions(Array.from(langSet));
      }
    };
    fetchProviders();
  }, []);

  useEffect(() => {
    setLLMProvider(model?.metadata?.LLM_provider);
    setLLMModel(model?.metadata?.LLM_model);
    setLLMLanguage(model?.metadata?.language);
  }, [model]);

  useEffect(() => {
    const extractLangKeysForModel = (all_prompts: Provider[], modelName: string, providerName: string): any[] => {
      const provider = all_prompts.find((p) => p.provider === providerName);

      if (!provider) {
        throw new Error(`Provider ${providerName} not found `);
      }

      const model = provider.models.find((m) => m.model === modelName);

      if (!model) {
        throw new Error(`Model ${modelName} not found in provider ${providerName}`);
      }
      const val = Object.values(model.langs).map((lang: any) => {
        return lang.language;
      });
      return val;
    };
    if (LLMmodel !== '' && LLMprovider !== '' && LLMprovider !== undefined && LLMmodel !== undefined && allPrompts.length !== 0 && allPrompts !== undefined) {
      const temp_lang = extractLangKeysForModel(allPrompts, LLMmodel, LLMprovider);
      setLanguageOptions(temp_lang);
      setLLMLanguage('');
    }
  }, [LLMmodel]);

  useEffect(() => {
    const extractModelKeys = (allPrompts: Provider[], providerName: string): string[] => {
      const provider = allPrompts.find((p) => p.provider === providerName);
      if (!provider) {
        throw new Error(`Provider ${providerName} not found`);
      }
      const models = new Array<string>();
      provider.models.forEach((model) => {
        models.push(model.model);
      });
      return models;
    };
    if (LLMprovider !== '' && LLMprovider !== undefined && allPrompts.length !== 0 && allPrompts !== undefined) {
      setModelOptions(extractModelKeys(allPrompts, LLMprovider));
      setLLMModel('');
      setLLMLanguage('');
    }
  }, [LLMprovider]);

  const handleSave = () => {
    const newModel = { ...model, metadata: { ...model?.metadata, LLM_provider: LLMprovider, LLM_model: LLMmodel, language: LLMlanguage } };
    const saveModel = async () => {
      const response = await axios.post(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/model/set`, { model: newModel });

      if (response.data.type === 'success') {
        dispatch({ type: 'DISPLAY_SUCCESS', payload: { reason: 'Provider and model has been saved' } });
      } else {
        dispatch({ type: 'DISPLAY_ERROR', payload: { reason: response.data.reason } });
      }
    };
    saveModel();
  };

  const handleProvider = (event: any) => {
    setModelDisable(false);
    setLLMProvider(event.target.value);
  };

  const handleModel = (event: any) => {
    setLangDisable(false);
    setLLMModel(event.target.value);
  };

  const handleLanguageChange = (event: any) => {
    setLLMLanguage(event.target.value);
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <h1> Select model and provider</h1>
      </div>
      <div style={{ display: 'flex', marginTop: 25 }}>
        <FormControl fullWidth>
          <InputLabel id="providers-label">{LLMprovider ? 'Providers' : 'Select provider'}</InputLabel>
          <Select id="providers-selector" value={LLMprovider} label="Providers" onChange={handleProvider}>
            {providerOptions.map((option) => {
              return <MenuItem value={option}>{option}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="model-label">{LLMmodel ? 'Model' : 'Select model'}</InputLabel>
          <Select id="model-selector" value={LLMmodel} label="model" disabled={LLMprovider === '' || modelDisable} onChange={handleModel}>
            {modelOptions.map((option) => {
              return <MenuItem value={option}>{option}</MenuItem>;
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="language-label">{LLMlanguage ? 'Languages' : 'Select language'}</InputLabel>
          <Select id="language-selector" value={LLMlanguage} key={LLMlanguage} label="Language" disabled={LLMmodel === '' || langDisable} onChange={handleLanguageChange}>
            {languageOptions.map((option) => {
              return <MenuItem value={option}>{option}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </div>
      <PrimaryButton
        text="Save provider and model"
        handleClick={() => {
          handleSave();
        }}
      />
    </>
  );
}

export default SelectModel;
