import React from 'react';
import { createRoot } from 'react-dom/client';

import { StyledEngineProvider } from '@mui/material/styles';

import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

import i18n from 'i18next';
import { initReactI18next, withTranslation } from 'react-i18next';

import { getLocalLanguage } from './utils/language';
import da_DK from './lang/da_DK.json';
import reducers from './reducers';
import { BrowserRouter } from 'react-router-dom';
import UserRoutes from './routes/Routes';
import DisplayMessage from './components/messages/DisplayMessage';

import '@fontsource/nunito/300.css';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/500.css';
import '@fontsource/nunito/700.css';
import './assets/css/main.css';

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

i18n.use(initReactI18next).init({
  resources: {
    da_DK: da_DK,
  },
  lng: getLocalLanguage(),
  fallbackLng: 'en_EN',
  interpolation: {
    escapeValue: false,
  },
});

const Index = (props: any) => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <BrowserRouter>
            <UserRoutes {...props} />

            <DisplayMessage {...props} />
          </BrowserRouter>
        </StyledEngineProvider>
      </Provider>
    </React.StrictMode>
  );
};

const IndexApp = withTranslation()(Index);

root.render(<IndexApp />);
